<template>
  <div>
    <div class="page-container">
      <div class="flex items-center justify-between py-6">
        <MoleculesGlobalLogo color="neutral" />
        <div class="pl-6 pr-8 py-4 text-neutral-50 bg-primary-500 tablet:pl-6 tablet:pr-6 tablet:py-6">
          <MoleculesGlobalCartIcon :total-price="cart.prices.value.itemsTotal.value - cart.prices.value.itemsCouponSale.value" :total-quantity="cart.totalQuantity.value" :cart-items="cart.presentation.value" />
        </div>
      </div>
    </div>
    <div>
      <div class="fixed top-0 w-full z-[10000]">
        <OrganismsDialogsMessage :show="message.show && usePage().value?.page?.module.id !== 3" :icon="message.icon" :text="message.text" :button-text="message.buttonText" :type="message.type" @hide="hideMessage" />
      </div>
      <slot></slot>
    </div>
    <div class="mb-8 text-xs uppercase text-center text-neutral-300">
      Copyright &copy; 2011-{{ new Date().getFullYear() }} Rigad
    </div>
  </div>
</template>
<script setup>

const cart = useCart();
const i18n = useI18n();
const route = useRoute();
const message = useMessage().message;

const hideMessage = () => {
  useMessage().hide();
}

if (route.query.failReason) {
  let messageText;

  switch (route.query.failReason) {
    case 'invalidemail':
      messageText = i18n.t('Zadaný e-mail je neplatný');
      break;
    case 'emailbanned':
      messageText = i18n.t('Pro objednání zadejte jinou e-mailovou adresu');
      break;
    case 'couponnotvalid':
      case 'invalidcoupon':
      messageText = i18n.t('Slevový kupón není platný');
      break;
    case 'invaliddic':
      messageText = i18n.t('Zadané DIČ je neplatné');
      break;
    case 'invalidshippingpayment':
      messageText = i18n.t('Vybraná kombinace dopravy a platby není možná');
      break;
    case 'productnotfound':
      messageText = i18n.t('Zboží v košíku již není dostupné');
      break;
    case 'combinationofelectronicandclassicproduct':
      messageText = i18n.t('Nelze objednat elektronický a klasický produkt současně');
      break;
    case 'invalidbranch':
      messageText = i18n.t('Neplatné výdejní místo - vyberte prosím jiné');
      break;
    case 'invalidcartcontent':
      messageText = i18n.t('Neplatný obsah košíku');
      break;
    case 'shippingaddressnotset':
      messageText = i18n.t('Vyberte dopravu a platbu');
      break;
    case 'cartisempty':
      messageText = i18n.t('Košík je prázdný');
      break;
  }

  useMessage().show(messageText, null, true);
}

</script>